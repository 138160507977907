// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-en-jsx": () => import("/opt/build/repo/src/pages/en.jsx" /* webpackChunkName: "component---src-pages-en-jsx" */),
  "component---src-pages-fi-jsx": () => import("/opt/build/repo/src/pages/fi.jsx" /* webpackChunkName: "component---src-pages-fi-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("/opt/build/repo/src/pages/privacy_policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

